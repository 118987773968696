import { IncentivesPrice, StyledSubtitle, Title, Option } from '@/uiComponents/CommonComponents'
import { Container } from './CommonCalculator'
import Select from './Select'
import styled from 'styled-components'
import { usdFormatter } from '@/salesAssistant/packagePreview/usdFormatter'
import { CalculatorResponse } from './computeCalculatorApi'

const EstimatedSystemTitle = styled(Title)`
  margin-bottom: 40px;
`

const SystemOptionContainer = styled.div`
  display: flex;
  width: 204px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 14px;
  border-radius: var(--radius-2xl);
  background-color: var(--gray-modern-50);
  gap: 8px;
`

const SystemOptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 0;
  gap: 24px;
`

type EstimatedSystemProps = {
  calculatorProfits: CalculatorResponse
  period: 'monthly' | 'yearly'
  setPeriod: React.Dispatch<React.SetStateAction<'monthly' | 'yearly'>>
}

const EstimatedSystem = ({ calculatorProfits, period, setPeriod }: EstimatedSystemProps) => {
  const { yearlyProfits } = calculatorProfits
  const isMonthly = period === 'monthly'

  const formatProfitValue = (value: number) => usdFormatter.format(isMonthly ? value / 12 : value)

  const profitItems = [
    { label: 'Electricity Bill Savings', value: yearlyProfits.electricBillSavings },
    { label: 'Selling Energy (Grid)', value: yearlyProfits.sellingEnergy },
    { label: 'GPU Rent', value: yearlyProfits.gpuRent },
    { label: 'Storage Rent', value: yearlyProfits.storageRent },
    { label: 'Total', value: yearlyProfits.total },
  ]

  return (
    <Container>
      <EstimatedSystemTitle>Estimated system cost & profits</EstimatedSystemTitle>
      <Select
        width="264"
        onChange={(value) => {
          setPeriod(value as 'monthly' | 'yearly')
        }}
        value={period}
      >
        <Option value="monthly">Monthly</Option>
        <Option value="yearly">Yearly</Option>
      </Select>
      <SystemOptionsWrapper>
        {profitItems.map(({ label, value }) => (
          <SystemOptionContainer key={label}>
            <StyledSubtitle>{label}</StyledSubtitle>
            <IncentivesPrice>{formatProfitValue(value)}</IncentivesPrice>
          </SystemOptionContainer>
        ))}
      </SystemOptionsWrapper>
    </Container>
  )
}

export default EstimatedSystem
