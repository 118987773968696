import styled from 'styled-components'
import { Container, OptionsWrapper } from './CommonCalculator'
import { Title } from '@/uiComponents/CommonComponents'
import ToggleOption from './ToggleOption'
import { useCallback } from 'react'

const ProfitSourcesContainer = styled(Container)`
  max-width: 300px;
  margin-bottom: 10px;
  gap: 20px;
`

const ProfitSourcesWrapper = styled(Container)`
  gap: 20px;
`

const RentWrapper = styled(OptionsWrapper)<{ $isVisible: boolean }>`
  overflow: hidden;
  max-width: 500px;
  max-height: ${({ $isVisible }) => ($isVisible ? '500px' : '0px')};
  flex-direction: row;
  padding: ${({ $isVisible }) => ($isVisible ? '20px' : '0px')};
  margin-bottom: unset;
  gap: 20px;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  outline: ${({ $isVisible }) => ($isVisible ? '1px solid var(--gray-modern-300)' : 'none')};
  transform: ${({ $isVisible }) => ($isVisible ? 'translateY(0)' : 'translateY(-10px)')};
  transition:
    max-height 0.5s ease,
    opacity 0.5s ease,
    padding 0.5s ease,
    transform 0.5s ease,
    outline 0.5s ease;
`

const RentContainer = styled(Container)`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`

type ProfitsSourcesType = {
  electricBillSavings: boolean
  sellingEnergy: boolean
  gpuRent: boolean
  storageRent: boolean
}

type ProfitSourcesProps = {
  rentProbability: number
  rentHourlyRate: number
  profitsSources: ProfitsSourcesType
  setProfitsSources: React.Dispatch<React.SetStateAction<ProfitsSourcesType>>
}

const ProfitSources = ({ rentHourlyRate, rentProbability, profitsSources, setProfitsSources }: ProfitSourcesProps) => {
  const handleRentToggle = useCallback(
    (source: keyof ProfitsSourcesType) => (value: boolean) => {
      setProfitsSources((prevSources) => ({
        ...prevSources,
        [source]: value,
      }))
    },
    [setProfitsSources]
  )

  return (
    <Container>
      <Title>Profit Sources</Title>
      <ProfitSourcesWrapper>
        <ProfitSourcesContainer>
          <ToggleOption text="Electricity Bill Savings" disabled />
          <ToggleOption text="Selling Energy (Grid)" onChange={handleRentToggle('sellingEnergy')} />
          <ToggleOption text="Storage Rent" onChange={handleRentToggle('storageRent')} />
          <ToggleOption text="GPU Rent" onChange={handleRentToggle('gpuRent')} />
        </ProfitSourcesContainer>
        <RentWrapper $isVisible={profitsSources.gpuRent}>
          <RentContainer>
            <h3>Monthly Load:</h3>
            <span>{isNaN(rentProbability) ? 0 : rentProbability}%</span>
          </RentContainer>
          <RentContainer>
            <h3>Hourly Rate:</h3>
            <span>${isNaN(rentHourlyRate) ? 0 : rentHourlyRate}</span>
          </RentContainer>
        </RentWrapper>
      </ProfitSourcesWrapper>
    </Container>
  )
}

export default ProfitSources
